<template>
    <div class="app-header block border-bottom">
        <nav class="navbar navbar-expand-lg navbar-light bg-white py-4">
            <div class="container-fluid">
                <Brand></Brand>

                <div class="flex-fill"></div>

            </div>
        </nav>
    </div>
</template>

<script>
import Brand from "@/components/Brand.vue";

export default {
	name: "AppHeaderPublic",
    components: {
        Brand,
        // MaterialIcons
    },
	props: {},
};
</script>

<style scoped>
.app-header .form-inline {
	width: 50%;
}
.app-header .navbar-brand {
	font-size: 24px;
}
.app-header .nav-item i {
	font-size: 20px;
}
@media (max-width: 767.98px) {
	.app-header .form-inline {
		width: 18%;
	}
	.app-header .navbar-collapse {
		flex-basis: 0;
	}
	.app-header .navbar-nav {
		flex-direction: row;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.app-header .navbar-collapse {
		flex-basis: 0;
	}
	.app-header .navbar-nav {
		flex-direction: row;
	}
}
</style>
