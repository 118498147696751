<template>
    <form @submit.prevent="submit($event)" class="border shadow-sm rounded form-login bg-white pb-5">
        <h3 class="fw-normal text-center mb-4 pb-2">Reset Password</h3>

        <div class="form-group mb-4">
            <div class="custom-control custom-checkbox">
                Create a secure password (6 characters or more). You may use letters, numbers, or special character.
            </div>
        </div>

        <div class="form-group mb-4">
            <input
                type="password"
                id="pass"
                name="pass"
                class="form-control"
                placeholder=""
                v-model="pass"
                @keydown="hideErrors"
            />
            <small id="emailHelp" class="form-text text-muted">Enter your new password.</small>
        </div>

        <div class="form-group mb-4">
            <input
                type="password"
                id="confirmPass"
                name="confirmPass"
                class="form-control"
                placeholder=""
                v-model="confirmPass"
                @keydown="hideErrors"
            />
            <small id="emailHelp" class="form-text text-muted">Confirm your password.</small>
        </div>


        <p class="alert alert-warning mb-2" v-html="errors" v-if="errors.length"></p>

        <div class="form-group">
            &nbsp;
        </div>

        <div class="form-group d-grid mb-4">
            <button
                type="submit"
                class="btn btn-primary btn-lg btn-block"
                :class="[pass?.length > 6]"
                @click="submit($event)"
            >
                Update Password
            </button>
        </div>




        <div class="form-group mb-4" >
            <p class="text-muted mb-1 pt-2">
                <a href="#"
                    @click.prevent="login">Login</a>
            </p>
            <p class="text-muted">
                <span>Can't login? </span><a href="#"
                    @click.prevent="resetPassword">Reset Password</a>
            </p>
        </div>



    </form>
</template>

<script>

import {validateEmail} from "@/plugins/helper";

export default {
  name: "ResetPassword",
  components: {
  },

    data(){
        return {
            // URLS: {
            //     SAPI_REMOTE: 'https://sapi.cloud',
            //     SAPI_LOCALHOST: 'http://localhost:9090'
            // },
            // email: '',
            pass: '', 
            confirmPass: '', 
            errorMessage: ''
            // emits: {
            //     LOGIN_SUCCESS: 'onloginsuccess',
            //     LOGIN_ERROR: 'onloginerror'
            // }
        }
    }, 


    computed: {

        isPasswordValid() {
            return false;
        },

        errors() {
            return this.errorMessage;
            // return '<p>something went wrong.</p>';
        }
    },


    methods: {

        showErrors(errors) {
            this.errorMessage = errors.join('; ');
        }, 


        hideErrors() {
            this.errorMessage = '';
        }, 

        /**
         * Submits login form.
         * 
         * IMPORTANT: if credentials are valid, the user will be logged in and the user object will be
         * set in the applications ($root.user). Once the data changes whe application will redirect
         * to the specified default view. 
         * 
         * @param {Object} event
         * 
         * @see $root.user
         * @see $root.watch 
         */
        async submit(event) {
            event.preventDefault();
            this.$store.state.errors.signOn = '';

            let token = this.$route.query?.otk;

            if(!token || token?.length === 0) {
                this.$root.notify({
                    type: 'alert',
                    title: 'ERROR', 
                    message: 'Invalid or missing OTK (One Time Key).'
                });
                return;
            }

            if(this.pass.length === 0 || this.confirmPass.length === 0) {
                this.$root.notify({
                    type: 'alert',
                    title: 'ERROR', 
                    message: 'Invalid or missing password value(s).'
                });
                return;
            }

            if(this.pass.length < 6) {
                this.$root.notify({
                    type: 'alert',
                    title: 'ERROR', 
                    message: 'Your password must be at least 6 characters long.'
                });
                return;
            }
            
            if(this.pass !== this.confirmPass) {
                this.$root.notify({
                    type: 'alert',
                    title: 'ERROR', 
                    message: 'Passwords do not match.'
                });
                return;
            }
            
            let {success, message /** the email address */} = await this.$store.getters.wp.run('resetPassword', [token, this.pass]);
            let email = message;
            /^localhost/.test(document.location.host) && console.log('email', message);

            if(success && validateEmail(email)) {
                await this.signOn(email, this.pass);

            } else {
                this.$root.notify({
                    type: 'alert',
                    title: 'ERROR', 
                    message: message
                });
            }
        }, 



        async signOn(email, pass) {
            let creds = {
                user_login: email,
                user_password: pass,
                remember: true
            };

            let response = await this.$store.dispatch('signOn', creds);

            console.log(response);

            // this.$router.push({
            //     path: '/'
            // });
        }, 


        resetPassword() {
            this.$root.resetPasswordIntent();
        },

        login() {
            this.$router.push({
                path: '/login'
            });
        },

    }, 
};
</script>

<style scoped>

.black-and-white-img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.4;
}


input[type="email"],
input[type="text"],
input[type="password"] {
    outline: none;
    width: 100%;
    padding: 14px 20px;
    font-size: 18px;
    line-height: 20px;
    outline: 0;
    letter-spacing: 0px;
    color: #616161 !important;
    height: 50px;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}


form {
    padding: 40px 50px 20px;
}

.or {
    width: 32px;
    height: 32px;
}


.login-divider {
    z-index: 1;
}
.login-divider::before {
    content: '';
    display: block;
    width: 100%;
    border-top: 1px solid #dee2e6!important;
    margin-top: 16px;
    position: absolute;
    z-index: -1;
}

.btn-sign-in img {
    width: 65%;
}

.btn-social .btn-label {
    line-height: 28px;
}
.btn-social {
    border: 1px solid #958e8e8c;
}
.btn-social:hover {
    border-color: #3f3c3c8c;
    color: #333333c4 !important;
}

</style>
